import { Alert, Grid, Snackbar, Typography } from '@mui/material';
import { Helmet } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endpoints } from '../../utils/endpoints';
import Layout from '../Layout';
import Processing from '../Processing';
import BackToLoginButton from './BackToLoginButton';
import Form from './Form';

interface TokenCheckResponse {
    exists: boolean;
    isValid?: boolean;
}

export default function ForgottenPassword() {
    const { token } = useParams() as { token: string }; //mindig van token, különben nem ez a komponens renderelődne
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState<TokenCheckResponse | null>(null);
    const [message, setMessage] = useState<{
        open: boolean;
        message: string;
        type: 'success' | 'error';
    }>({
        open: false,
        message: '',
        type: 'error',
    });
    const [changed, setChanged] = useState(false);

    useEffect(
        () => setChanged((prev) => prev || message.type === 'success'),
        [message.type]
    );

    useEffect(() => {
        axios
            .post(endpoints.forgottenPassword + '/' + token, {})
            .then((response: AxiosResponse<TokenCheckResponse>) =>
                setState(response.data)
            )
            .finally(() => setLoading(false));
    }, [token]);

    const content = (): { message: string; content: JSX.Element } => {
        if (state) {
            if (state.exists) {
                if (state.isValid) {
                    return {
                        message: changed
                            ? 'Sikeres jelszóváltoztatás!'
                            : 'Add meg új jelszavadat',
                        content: <Form token={token} setMessage={setMessage} />,
                    };
                }

                return {
                    message: 'Az új jelszót igénylő link már felhasználásra került vagy lejárt',
                    content: <BackToLoginButton />,
                };
            }

            return {
                message: 'Az új jelszót igénylő link nem létezik',
                content: <BackToLoginButton />,
            };
        }

        return {
            message: 'Hiba a feldolgozás során',
            content: <BackToLoginButton />,
        };
    };

    return (
        <Layout>
            <Helmet project="MCC" title="Elfelejtett jelszó" />    
            {loading ? (
                <Processing />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography textAlign='center' variant='h5'>
                            {content().message}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        justifyContent='center'
                        textAlign='center'
                    >
                        {content().content}
                    </Grid>
                </Grid>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={message.open}
                autoHideDuration={4000}
                onClose={() =>
                    setMessage({ open: false, message: '', type: 'error' })
                }
            >
                <Alert severity={message.type}>{message.message}</Alert>
            </Snackbar>
        </Layout>
    );
}
