const formatURL = (url?: string) => {
    if (!url?.endsWith('/')) {
        url += '/';
    }
    return url;
}

export const targets = {
    tar: {
        query: "tar",
        target: process.env.REACT_APP_TARGET_TAR || "https://mcctar.silinfo.dev"
    },
    tar_admission: {
        query: "tar_admission",
        target: formatURL(process.env.REACT_APP_TARGET_TAR) + 'felveteli/mcc' || "https://mcctar.silinfo.dev/felveteli/mcc"
    },
    mccenrolment: {
        query: "enrollment",
        target: process.env.REACT_APP_TARGET_MCCENROLMENT || "https://mccepregisztracio.silinfo.dev"
    },
    central: {
        query: "central",
        target: process.env.REACT_APP_TARGET_CENTRAL || "https://tenant-mcccentral.silinfo.dev",
    }
}