import Grid from "@mui/material/Grid";
import { LoadingButton as Button } from "@mui/lab";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useState } from "react";
import api from "../api";
import { STATUS, Status, Tenant } from "../App";
import { targets } from "../utils/targets";
import { endpoints } from "../utils/endpoints";
import { delJWT } from "../utils/cookies";
import { ReactComponent as VectorIcon } from '../icons/Vector.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonContent from "./ButtonContent";

interface TenantSelectorActivationProps {
    isCentralAdmin: boolean;
    tenants: Tenant[];
    setStatus: (status: Status) => void;
    setSelectedTenant: React.Dispatch<React.SetStateAction<Tenant | null>>;
}

export default function TenantSelectorActivation({
    isCentralAdmin,
    tenants,
    setStatus,
    setSelectedTenant,
}: TenantSelectorActivationProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setLoading(true);
    api
      .post(endpoints.logout)
      .then(() => {
        delJWT();
        setStatus(STATUS.UNAUTHORIZED);
        window.location.href = "/";
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={16} align="center">
          Kérlek, válassz az alábbi rendszerek közül:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isCentralAdmin && (
            <Button
                fullWidth
                color="secondary"
                variant="contained"
                size="large"
                sx={{
                    padding: "8px",
                    height: 38,
                }}
                onClick={() => {
                  window.location.href = targets.central.target;
                }}
                loading={loading}
            >
                <ButtonContent 
                  StartIcon={<VectorIcon />} 
                  buttonText={"CENTRAL"} 
                  EndIcon={<LoginIcon />}
                />
            </Button>
        )}
      </Grid>
      {tenants.map((tenant) => (
        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            size="large"
            sx={{
              padding: "8px",
              height: 38,
              display: "flex",
              justifyContent: "space-between",
              textTransform: 'none'
            }}
            onClick={() => {
              setStatus(STATUS.LOGGED_IN);
              setSelectedTenant(tenant);
            }}
            loading={loading}
          >
            <ButtonContent 
              StartIcon={<div />} 
              buttonText={tenant.name} 
              EndIcon={<ArrowForwardIosIcon />}
            />
          </Button>
      </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          size="large"
          sx={{
            background: "#F5F5F5",
            padding: "8px",
            height: 38,
            display: "flex",
            justifyContent: "space-between",
            textTransform: 'none'
          }}
          loadingPosition="end"
          loading={loading}
          onClick={handleLogout}
        >
          <ButtonContent 
            StartIcon={<div />} 
            buttonText={"Kijelentkezés"} 
            EndIcon={<LogoutIcon />}
          />
        </Button>
      </Grid>
    </Grid>
  );
}
