import Layout from "./components/Layout";
import "./App.scss";
import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import LoginForm from "./components/LoginForm";
import api from "./api";
import { AxiosResponse } from "axios";
import LoggedInView from "./components/LoggedInView";
import Processing from "./components/Processing";
import { endpoints } from "./utils/endpoints";
import { delJWT, getJWT } from "./utils/cookies";
import { redirectToTarget } from "./utils/redirectToTarget";
import { Helmet } from "@silinfo/front-end-template";
import NotificationDialog from "./components/NotificationDialog";
import { useSearchParams } from "react-router-dom";
import TenantSelector from "./components/TenantSelector";
import * as Sentry from '@sentry/react';

export type Status = "LOGGED_IN" | "UNAUTHORIZED" | "LOADING" | "TENANT_SELECT";

interface IStatus {
  [key: string]: Status;
}

const SERVER_MAINTENANCE_STATUS = ['500', '502', '503'];

export const STATUS: IStatus = {
  LOGGED_IN: "LOGGED_IN",
  UNAUTHORIZED: "UNAUTHORIZED",
  LOADING: "LOADING",
  TENANT_SELECT: "TENANT_SELECT",
};

export interface Tenant {
  id: number;
  name: string;
  tar_endpoint: string;
  epreg_endpoint: string;
  epreg_be_endpoint: string;
  deleted_at: Date;
}

export default function App() {
  const [error, setError] = useState<string>("");
  const [status, setStatus] = useState<Status>("LOADING");
  const [profile, setProfile] = useState<string>("");
  const [isCentralAdmin, setIsCentralAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [searchParams] = useSearchParams();
  const [enrollmentAccessMap, setEnrollmentAccessMap] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (SERVER_MAINTENANCE_STATUS.includes(searchParams.get("error-status") || "")) {
      setOpen(true);
    }
  }, [searchParams]);

  const check = () => {
    setStatus(STATUS.LOADING);
    api
      .get(endpoints.check)
      .then((response : AxiosResponse) => {
        const { firstName, lastName, isCentralAdmin, tenants, id, email, epregAccessMap } = response.data;  
        setProfile(lastName + " " + firstName);
        setIsCentralAdmin(isCentralAdmin);
        setTenants(tenants);
        setSelectedTenant(tenants[0] ?? null);
        setEnrollmentAccessMap(epregAccessMap);
        
        Sentry.setUser({
          id: '' + id,
          email,
        });

        // belépés után, ha a felhasználó benne van több tenantban is a tenant választó felület jelenik meg
        if (tenants?.length > 1 || isCentralAdmin) {
          setStatus(STATUS.TENANT_SELECT);
        }
        else {
          setStatus(STATUS.LOGGED_IN);
        }
        redirectToTarget(tenants);
      })
      .catch(() => {
        delJWT();
        setStatus(STATUS.UNAUTHORIZED);
      });
  };

  useEffect(() => {
    if (getJWT()) {
      check();
    } else {
      setStatus(STATUS.UNAUTHORIZED);
    }
  }, []);

  const getContent = () => {
    switch (status) {
      case STATUS.LOADING:
        return <Processing/>;
      case STATUS.TENANT_SELECT:
        return <TenantSelector isCentralAdmin={isCentralAdmin} tenants={tenants} profile={profile} setStatus={setStatus} setSelectedTenant={setSelectedTenant} />;
      case STATUS.LOGGED_IN:
        return <LoggedInView 
                  selectedTenant={selectedTenant}
                  profile={profile}
                  setStatus={setStatus}
                  showLogOutButton={tenants.length === 1 && !isCentralAdmin}
                  hasEpregAccess={enrollmentAccessMap?.[selectedTenant?.id ?? 0] ?? true}
                />;
      default:
        return <LoginForm setError={setError} check={check}/>;
    }
  };

  return (
    <Layout>
      <Helmet project="MCC" title="Bejelentkezés"/>
      {getContent()}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!error}
        autoHideDuration={2000}
        onClose={() => setError("")}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <NotificationDialog 
        openState={[open, setOpen]} 
        title="Karbantartás" 
        message="A rendszer jelenleg karbantartás alatt áll, kérjük, próbáld újra később!"
      />
    </Layout>
  );
}
