import axios from "axios";
import { getJWT } from "../utils/cookies";


const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
    },
    responseType: "json",
});

api.interceptors.request.use(
    (config: any) => {
        if (!config.headers.Authorization) {
            const AUTH_TOKEN = getJWT();
            if (AUTH_TOKEN) config.headers.Authorization = AUTH_TOKEN;

            const XSwitchUser = localStorage.getItem("X-Switch-User");
            if (XSwitchUser) config.headers['X-Switch-User'] = XSwitchUser;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => Promise.reject(error)
);

export default api;
