import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { endpoints } from '../../utils/endpoints';
import MCCLoading from '../Loading';
import { delJWT } from './../../utils/cookies';

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        api.post(endpoints.logout, {}).finally(() => {
            delJWT();
            navigate('/');
        });
    }, [navigate]);

    return <MCCLoading />;
}
