import { CancelButton } from "@silinfo/front-end-template";
import { useLocation, useNavigate } from "react-router-dom";

const BackToLoginButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = location.search;

    return (
        <CancelButton fullWidth size="large" onClick={() => navigate("/" + queryParams)}>
            Vissza a bejelentkezésre
        </CancelButton>
    );
};

export default BackToLoginButton;
