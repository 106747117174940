import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

interface NotificationDialogProps {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    title: string;
    message: string;
}

export default function NotificationDialog({openState, title, message}: NotificationDialogProps) {
    const [open, setOpen] = openState;

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="contained">OK</Button>
            </DialogActions>
        </Dialog>
    );
}