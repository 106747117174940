export const endpoints = {
    check: process.env.REACT_APP_BACKEND_URL + "/check",
    login: process.env.REACT_APP_BACKEND_URL + "/login",
    logout: process.env.REACT_APP_BACKEND_URL + "/logout",
    version: process.env.REACT_APP_BACKEND_URL + "/",
    forgottenPassword: process.env.REACT_APP_BACKEND_URL + "/forgotten-password",
    activation: process.env.REACT_APP_BACKEND_URL + "/activation",
    getTenantEndpoint: process.env.REACT_APP_BACKEND_URL + "/tenant/get-endpoint/:id",
    epreg: {
        profile: (epregEndpoint: string) => `${epregEndpoint}/profile`,
    },
};
