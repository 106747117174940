import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getJWT = () => {
    return cookies.get(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt');
};

export const setIsNewLogin = () => {
    cookies.set('is_new_login', 'true', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const setJWT = (token: string) => {
    cookies.set(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt', token, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};
export const delJWT = () => {
    cookies.remove(process.env.REACT_APP_COOKIES_NAME || 'mcc_jwt', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};
export const removeLayoutType = () => {
    cookies.remove('layout_type', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};
