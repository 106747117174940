import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import { CancelButton, SubmitButton } from "@silinfo/front-end-template";
import axios from "axios";
import { useEffect, useState } from "react";
import theme from "../theme";
import { endpoints } from "../utils/endpoints";
import TextField from "./TextField";

function Opener({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          sx={{
            color: theme.palette.primary.main,
            fontSize: "medium",
            borderBottom: "1px solid white",
            background: "none",
            textTransform: "none",
            "&:hover": {
              borderBottom: "1px solid " + theme.palette.primary.main,
              cursor: "pointer",
            },
            textDecoration: "none",
            padding: 0,
          }}
          disableRipple
          onClick={() => setOpen(true)}
        >
          Elfelejtett jelszó
        </Button>
      </Grid>
    </Grid>
  );
}

export default function ForgotPassword({
  setError,
  formEmail,
}: {
  setError: (value: string) => void;
  formEmail: string;
}) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    open && setEmail(formEmail);
    setSuccess(false);
  }, [formEmail, open]);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(endpoints.forgottenPassword, {
        email,
      })
      .then(() => setSuccess(true))
      .catch(() => setError("Hiba a feldolgozás során"))
      .finally(() => setLoading(false));
  };

  const isError = (): string => {
    if (!email) return "Kötelező kitölteni!";
    if (!regex.test(email)) return "Hibás e-mail formátum!";
    return "";
  };

  return (
    <>
      <Opener setOpen={setOpen} />
      <Dialog open={open} fullWidth onBackdropClick={() => setOpen(false)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogTitle>Elfelejtett jelszó</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {success
                    ? "Sikeres mentés! Amennyiben helyes e-mail címet adtál meg, kérjük, ellenőrizd a postafiókodat, ahová további információkat küldtünk!"
                    : "Kérjük, add meg az e-mail címedet!"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {!success && (
                    <Grid item xs={12}>
                      <TextField
                        label="E-mail cím"
                        name="email"
                        margin="normal"
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!isError()}
                        helperText={isError()}
                        size="medium"
                        value={email}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              {!success && (
                <SubmitButton
                  disabled={!!isError()}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Mehet
                </SubmitButton>
              )}
              <CancelButton onClick={() => setOpen(false)}>Vissza</CancelButton>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
