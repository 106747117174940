import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Logo from "../images/logo.svg";
import BackgroundLogo from "../images/MCClogo_negacolor.svg";
import Versions from "./Versions";
import theme from "../theme";

export default function Layout({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) {

  return (
    <Grid
      container
      component="main"
      style={{
        height: "100vh",
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: `url(${BackgroundLogo})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: theme.palette.secondary.main,
          backgroundSize: "80%",
          backgroundPosition: "center",
          boxShadow: "20px",
        }}
        component={Paper}
        elevation={20}
        square
      />
      <Grid item xs={12} sm={8} md={5}>
        <div style={{
          margin: theme.spacing(8, 4),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Avatar
            src={Logo}
            variant="square"
            sx={{ height: "100%", width: "30%", margin: "8px" }}
          />
          {children}
        </div>
      </Grid>
      <Versions />
    </Grid>
  );
}
