import Grid from "@mui/material/Grid";
import { LoadingButton as Button } from "@mui/lab";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useEffect, useState } from "react";
import api from "../api";
import { STATUS, Status, Tenant } from "../App";
import { endpoints } from "../utils/endpoints";
import { delJWT } from "../utils/cookies";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ButtonContent from "./ButtonContent";

interface LoggendInViewProps {
    profile: string;
    setStatus: (status: Status) => void;
    selectedTenant: Tenant | null;
    showLogOutButton: boolean;
    hasEpregAccess: boolean;
}

export default function LoggedInView({
    profile,
    setStatus,
    selectedTenant,
    showLogOutButton,
    hasEpregAccess,
}: LoggendInViewProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogout = () => {
        setLoading(true);
        api.post(endpoints.logout)
            .then(() => {
                delJWT();
                setStatus(STATUS.UNAUTHORIZED);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!profile) {
            api.get(endpoints.check);
        }
    }, [profile]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography fontSize={18} align="center">
                    Bejelentkezve mint {profile}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={20} fontWeight={"bold"} align="center">
                {selectedTenant?.name}
                </Typography>
            </Grid>
            {selectedTenant ? (
                <>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            size="large"
                            sx={{
                                padding: "8px",
                                height: 38,
                                display: "flex",
                                justifyContent: "space-between",
                                textTransform: "none",
                            }}
                            onClick={() => {
                                window.location.href = selectedTenant?.tar_endpoint ?? "";
                            }}
                            loading={loading}
                        >
                            <ButtonContent
                                StartIcon={<div />}
                                buttonText={"Tanulmányi rendszer"}
                                EndIcon={<LoginIcon />}
                            />
                        </Button>
                    </Grid>
                    {hasEpregAccess && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                size="large"
                                sx={{
                                    padding: "8px",
                                    height: 38,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    textTransform: "none",
                                }}
                                onClick={() => {
                                    window.location.href = selectedTenant?.epreg_endpoint ?? "";
                                }}
                                loading={loading}
                            >
                                <ButtonContent
                                    StartIcon={<div />}
                                    buttonText={"Regisztrációs rendszer"}
                                    EndIcon={<LoginIcon />}
                                />
                            </Button>
                        </Grid>
                    )}
                </>
            ) : (
                <Grid item xs={12}>
                    <Typography align="center">
                    Nincs hozzáférésed egyetlen vonatkozó rendszerhez sem. Amennyiben jogosultságra van szükséged, kérjük, vedd fel a kapcsolatot az adminisztrátorokkal.
                    </Typography>
                </Grid>
            )}
            {showLogOutButton || !selectedTenant ? (
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color="secondary"
                        size="large"
                        sx={{
                            background: "#F5F5F5",
                            padding: "8px",
                            height: 38,
                            display: "flex",
                            justifyContent: "space-between",
                            textTransform: "none",
                        }}
                        loadingPosition="end"
                        loading={loading}
                        onClick={handleLogout}
                    >
                        <ButtonContent StartIcon={<div />} buttonText={"Kijelentkezés"} EndIcon={<LogoutIcon />} />
                    </Button>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color="secondary"
                        size="large"
                        sx={{
                            background: "#F5F5F5",
                            padding: "8px",
                            height: 38,
                            display: "flex",
                            justifyContent: "space-between",
                            textTransform: "none",
                        }}
                        loadingPosition="center"
                        loading={loading}
                        onClick={() => setStatus(STATUS.TENANT_SELECT)}
                    >
                        <ButtonContent StartIcon={<ArrowBackIosIcon />} buttonText={"Vissza"} EndIcon={<div />} />
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
