import { targets } from "./targets";

export const redirectToTarget = (tenants: any, setLoading?: any, check?: any) => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });

  const errorStatus = params.errorStatus ?? params['error-status'] ?? '';

  if (errorStatus === 'user_not_found' || errorStatus === '403') {
    return;
  }

  if(setLoading){
    setLoading(true);
  }

  setTimeout(async () => {
    let def = true;
    if (params.target) {
      if (params.target.includes(targets.tar.query)) {
        const redirectUrl = getTenantEndpoint(tenants, params);
        if (redirectUrl) {
          window.location.href = redirectUrl;
          def = false;
        }
      } else if (params.target.includes(targets.mccenrolment.query)) {
        const redirectUrl = getTenantEndpoint(tenants, params, false);
        if (redirectUrl) {
          window.location.href = redirectUrl;
          def = false;
        }
      } else if (params.target.includes(targets.central.query)) {
        window.location.href = targets.central.target;
        def = false;
      } 
    }
    if(def && setLoading){
      check?.();
      setLoading(false);
    }
  }, 0);
};

const getTenantEndpoint = (tenants: Record<string, any>[], params: any, isTar = true) => {
  try {
    // get the tenant id from the query param
    const queryParams = params.target.split('_');
    const id = queryParams[1] ? Number(queryParams[1]) : 1;
    
    const tenantObject = tenants.find((tenant) => tenant['id'] === id);

    if (!tenantObject) {
      console.error(`Tenant with ID ${id} not found.`);
  }
      
    return (isTar ? tenantObject?.['tar_endpoint'] : tenantObject?.['epreg_endpoint']);
  }
  catch(error) {
    console.error(error);
    return;
  }
}