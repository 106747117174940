import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Processing() {
  return (
    <Grid item container xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12} justifyContent="center">
        <Typography align="center" variant="h2">
          Azonosítás folyamatban<span className="dots"></span>
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center" style={{ display: "flex" }}>
        <CircularProgress size={120}/>
      </Grid>
    </Grid>
  );
}
