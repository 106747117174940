import Grid from "@mui/material/Grid";
import { LoadingButton as Button } from "@mui/lab";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useEffect, useState } from "react";
import api from "../api";
import { STATUS, Status, Tenant } from "../App";
import { targets } from "../utils/targets";
import { endpoints } from "../utils/endpoints";
import { delJWT } from "../utils/cookies";
import { ReactComponent as VectorIcon } from '../icons/Vector.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonContent from "./ButtonContent";

interface TenantSelectorProps {
    isCentralAdmin: boolean;
    tenants: Tenant[];
    profile: string;
    setStatus: (status: Status) => void;
    setSelectedTenant: React.Dispatch<React.SetStateAction<Tenant | null>>;
}

export default function TenantSelector({
    isCentralAdmin,
    tenants,
    profile,
    setStatus,
    setSelectedTenant,
}: TenantSelectorProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setLoading(true);
    api
      .post(endpoints.logout)
      .then(() => {
        delJWT();
        setStatus(STATUS.UNAUTHORIZED);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!profile) {
      api.get(endpoints.check);
    }
  }, [profile]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} align="center">
          Bejelentkezve mint {profile}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={16} align="center">
          Kérlek, válassz az alábbi rendszerek közül:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isCentralAdmin && (
            <Button
                fullWidth
                color="secondary"
                variant="contained"
                size="large"
                sx={{
                    padding: "8px",
                    height: 38,
                }}
                onClick={() => {
                  window.location.href = targets.central.target;
                }}
                loading={loading}
            >
                <ButtonContent 
                  StartIcon={<VectorIcon />} 
                  buttonText={"CENTRAL"} 
                  EndIcon={<LoginIcon />}
                />
            </Button>
        )}
        </Grid>
          {tenants.length > 0 ? (
            tenants.map((tenant) => (
              <Grid item xs={12} key={tenant.id}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "8px",
                    height: 38,
                    display: "flex",
                    justifyContent: "space-between",
                    textTransform: 'none'
                  }}
                  onClick={() => {
                    setStatus(STATUS.LOGGED_IN);
                    setSelectedTenant(tenant);
                  }}
                  loading={loading}
                >
                  <ButtonContent 
                    StartIcon={<div />} 
                    buttonText={tenant.name} 
                    EndIcon={<ArrowForwardIosIcon />}
                  />
                </Button>
              </Grid>
            ))
          ) : !isCentralAdmin && tenants.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center">
                Nincs hozzáférésed egyetlen vonatkozó rendszerhez sem. Amennyiben jogosultságra van szükséged, kérjük, vedd fel a kapcsolatot az adminisztrátorokkal.
              </Typography>
            </Grid>
          ) : null}
      <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          size="large"
          sx={{
            background: "#F5F5F5",
            padding: "8px",
            height: 38,
            display: "flex",
            justifyContent: "space-between",
            textTransform: 'none'
          }}
          loading={loading}
          onClick={handleLogout}
        >
          <ButtonContent 
            StartIcon={<div />} 
            buttonText={"Kijelentkezés"} 
            EndIcon={<LogoutIcon />}
          />
        </Button>
      </Grid>
    </Grid>
  );
}
