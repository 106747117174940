import { Grid, Typography } from '@mui/material'
import React from 'react'

interface ButtonContentProps {
    StartIcon: React.ReactNode;
    EndIcon: React.ReactNode;
    buttonText: string;
}

export default function ButtonContent({StartIcon, EndIcon, buttonText}: ButtonContentProps) {
  return (
    <>
        <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            {StartIcon}
        </Grid>
        <Grid item xs={10}>
            <Typography fontSize={15} textTransform={'uppercase'} fontWeight={"regular"}>
                {buttonText}
            </Typography>
        </Grid>
        <Grid item xs={1} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            {EndIcon}
        </Grid>
    </>
  )
}
