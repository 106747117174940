import { Grid } from '@mui/material';
import { SubmitButton } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form as FormikForm, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { endpoints } from '../../utils/endpoints';
import TextField from '../TextField';
import BackToLoginButton from './BackToLoginButton';

type PasswordSetResponse =
    | { success: true; message: undefined }
    | { success: undefined; message: string };

interface PasswordForm {
    password: string;
    passwordAgain: string;
}

const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d){8,32}.*$/;

const ForgottenPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Kötelező megadni')
        .matches(
            regex,
            'A jelszónak legalább 8 karaktert, egy kisbetűt, egy nagybetűt és egy számot kell tartalmaznia'
        ),
    passwordAgain: Yup.string()
        .oneOf([Yup.ref('password')], 'Nem egyezik a két jelszó')
        .required('Kötelező megadni'),
});

const Form = ({
    token,
    setMessage,
}: {
    token: string;
    setMessage: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            message: string;
            type: 'success' | 'error';
        }>
    >;
}) => {
    const initialValues: PasswordForm = { password: '', passwordAgain: '' };
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);

    const handleSubmit = (form: PasswordForm) => {
        setLoading(true);
        axios
            .post(endpoints.forgottenPassword + '/' + token, form)
            .then((response: AxiosResponse<PasswordSetResponse>) => {
                const { success, message } = response.data;
                setMessage({
                    open: true,
                    message: success ? 'Sikeres jelszóváltoztatás!' : message,
                    type: success ? 'success' : 'error',
                });
                setChanged(!!success);
            })
            .catch((error: AxiosError) => {
                if (error.response?.data.message) setMessage({
                    open: true,
                    message: error.response.data.message,
                    type: 'error',
                });
            })
            .finally(() => setLoading(false));
    };

    if (changed) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BackToLoginButton />
                </Grid>
            </Grid>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ForgottenPasswordSchema}
        >
            {({ errors, touched }) => (
                <FormikForm noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field as='div' field='password'>
                                <TextField
                                    fullWidth
                                    label='Jelszó'
                                    name='password'
                                    type='password'
                                    required
                                    error={
                                        !!(touched.password && errors.password)
                                    }
                                    helperText={
                                        touched.password && errors.password
                                    }
                                />
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field as='div' field='password'>
                                <TextField
                                    fullWidth
                                    required
                                    label='Jelszó ismét'
                                    type='password'
                                    name='passwordAgain'
                                    error={
                                        !!(
                                            touched.passwordAgain &&
                                            errors.passwordAgain
                                        )
                                    }
                                    helperText={
                                        touched.passwordAgain &&
                                        errors.passwordAgain
                                    }
                                />
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <SubmitButton
                                loading={loading}
                                disabled={
                                    !!(errors.password || errors.passwordAgain)
                                }
                                fullWidth
                                type='submit'
                                size='large'
                            >
                                Küldés
                            </SubmitButton>
                        </Grid>
                        <Grid item xs={12}>
                            <BackToLoginButton />
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
};

export default Form;