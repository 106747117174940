import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { CancelButton } from "@silinfo/front-end-template";

const ExpiredPasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
}) => {
  return (
    <>
      <Dialog open={isOpen} fullWidth onBackdropClick={() => onClose(false)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogTitle>Lejárt jelszó</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Jelszavad lejárt, kérjük, ellenőrizd postafiókodat, ahová
                  további információkat küldtünk!
                </Typography>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <CancelButton onClick={() => onClose(false)}>Vissza</CancelButton>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ExpiredPasswordModal;
