import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#f3ca12',
            contrastText: '#fff',
        },
        secondary: {
            main: '#364354',
            contrastText: '#f3ca12'
        },
    },
});

export default theme;